.createconversation .conversationcard .customercardstart {
    padding: 0;
    display: block;
}
.createconversation .conversationcard .customercardstart:hover{
    background-color: white !important
}
.createconversation .customerimagediv {
    height: 67px;
    width: 75px;
    margin: 0; 
}
.convoscendddiv .forbetterview {
    display: flex;
    align-items: flex-start;
}
.convofristdiv {
    padding: 12px;
}
.convotextsec {
    padding-left: 14px;
    padding-top: 3px;
    width: 100%;
}
.convoscendddiv {
    /* padding: 0 12px; */
    position: relative;
    border-top: 1px solid #d9d9d9;
    padding-top: 20px;
    margin-top: 25px;
}
.conversationhappeneddiv {
    padding: 0 12px;
}
.conversationhappeneddiv .convoscendddiv.convothirddiv:first-child {
    border: none;
    margin-top: 0;
    padding-top: 0;
}
.alreadysharedbutton {
    color: #85807d;
    font-size: 14px;
    position: absolute;
    top: 8px;
    right: 15px;
}

span.customersecvalue.datevalueconvo {
    font-size: 11px;
}
.createconversation .attachedfile .customerimagediv {
    height: 60px;
    width: 60px;
    margin: 0;
    margin-right: 8px;
    margin-top: 8px;
    position: relative;
}
.createconversation .attachedfile .customerimagediv:hover .downloadimgdiv {
    opacity: 1;
    width: 100%;
    height: 100%;
    background: #444444a1;
}
.downloadimgdiv {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}
.dropzone.dz-started .dz-message {
    display: none;
}
.dropzone .dz-preview {
    display: inline-block;
    vertical-align: top;

}
.dropzone .dz-message{
    text-align: center;
    margin: 2em 0;
}
img.downloadimg {
  
    width: 22px;
    height: auto;
}
.conversation-replysection {
    padding: 0 12px 12px;
    background-color: #fff;
}
.onlyforaddconversation.conversation-replysection {
    background: transparent;
    margin-top: 15px;
    padding-top: 23px;
    border-top: 1px solid #d0d0d0;
}
.onlyforaddconversation .addtitleform .ant-form-item-label label {
    display: inline-block;
}
.onlyforaddconversation .addtitleform .ant-form-item-label label:before {
    position: absolute;
    right: -10px;
}
.Jobberclock_dashboard .iner-content .conversation-replysection h3 {
    font-size: 15px;
}
.createconversation .conversation-replysection .customerimagediv {
    width: 70px;
    height: 60px;
    margin: 0;
    margin-right: 12px;

}
.conversation-replysection textarea.ant-input {
    background-color: #f6f6f6;
    font-size: 12px;
    color: #5d5d5d;
}
.onlyforaddconversation  textarea.ant-input {
    background-color: #fff;
}
.conversationformstart {
    display: flex;
    align-items: flex-start;
}
.ant-row.ant-form-item.addtitleform {
    margin-bottom: 10px;
    
}
.createconversationformstart{
    display: flex;
    align-items: flex-end
}
.replyleftdiv {
    display: flex;
    align-items: flex-start;
}
.replylefftmaindiv,.replyrighttmaindiv {
    width: 100%;
}
.coversationtextareadiv .ant-row.ant-form-item,.coversationtextareadiv {
    width: 100%;
    margin-bottom: 0;
}

.ant-row.ant-form-item.coversationdropzondiv {
    width: 100%;
    padding: 0;
margin: 0
}
/* .conversationbtndiv {
    margin-left: 5em;
} */
.conversationbtndiv .ant-form-item {
    margin: 0;
}
.replyrighttmaindiv p.formsubsectiontitle {
    margin: 0;
    margin-left: 12px;
}
.replyrighttmaindiv .attachmentsection .filepicker {
    min-height: 190px !important;
    max-height:190px !important
}
.onlyforaddconversation .replyrighttmaindiv .attachmentsection .filepicker {
    min-height: 244px !important;
    overflow-y: auto;
    background: #fff;
    
    max-height: 244px !important;
}
.createconversation h3.heding.createconverstaintitle {
    padding: 0;
    margin: 0;
}
.onlyforaddconversation .replyrighttmaindiv p.formsubsectiontitle {
    margin: 0;
    margin-left: 0;
    margin-bottom: 8px;
}
h3.writecommnetheading {
    font-size: 14px !important;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
    display: flex;
    /* align-items: unset; */
    padding: 0 !important;
    margin-bottom: 7px !important;
    color: #333232 !important;
}
.conversationbtndiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}
.onlyforaddconversation button.editbtndesign {
    margin-right: 0;
}
.conversationchkbox span {
    color: #737373;
    font-size: 13px;
}
.addtitleform .ant-form-item-label {
    padding-bottom: 2px !important;
}
.addtitleform .ant-form-item-label label {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
    display: flex;
    align-items: unset;
    margin-bottom: 7px;
    color: #333232;
}
.attachmentsection {
    height: 100%;
    padding: 12px;
    background-color: white;
    border-radius: 4px;
}
.replyrighttmaindiv p.dropzonebtn {
    width: 33%;
}
.replyrighttmaindiv p.firstuploadtext {
    font-size: 15px;
}
.attachmentsection .filepicker.dropzone.dz-clickable {
    background-color: transparent;
    min-height: 400px;
    max-height: 400px;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    border: 2px dashed #f3f3f3;
    overflow-y: auto;
    position: relative;
    text-align: left;
    padding: 5px 0 5px 15px;
}
.replyrighttmaindiv .attachmentsection .dz-default.dz-message {
    position: absolute;
    left: 0;
    right: 0;
    top: 8% !important;
}
.onlyforaddconversation .attachmentsection .dz-default.dz-message {
    top: 19% !important;
}

.buttonComponent_start.returnpreviousbtn button.buttonactive.mainbuttoncls {
    background-color: transparent;
    padding: 1px 8px;
    text-transform: capitalize;
    color: #2d2d2d;
    border: 1px solid #bec1c5;
    opacity: 0.8;
    background: #bec1c5;
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttonComponent_start.returnpreviousbtn button.buttonactive.mainbuttoncls:hover{
    opacity: 1;
}
.returnpreviousbtn img.buttonimgcls {
    margin-right: 5px;
    width: 12px;    
}


.attachmentsection a.dz-remove {
    opacity: 0 !important;
    /* display: none; */
    font-size: 0 !important;
    position: absolute;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 30px;
    height: 30px;
    background: url(../Images/cancel.png);
    background-size: 14px;
    background-position: center;
    background-color: #eee;
    border-radius: 50%;
    padding: 14px;
    background-repeat: no-repeat;
}
@media screen and (min-width:320px) and (max-width:768px){
    .conversationformstart,.createconversationformstart{
        display: unset
    }
    .replylefftmaindiv, .replyrighttmaindiv{
        width: 100%
    }
    /* .conversationbtndiv{
        margin: 0
    } */
    .replyrighttmaindiv p.dropzonebtn {
        width: 48%;
    }
    
    
    .replyrighttmaindiv p.firstuploadtext{
        font-size: 15px
    }
    .onlyforaddconversation.conversation-replysection {
        padding-left: 0;
        padding-right: 0;
    }
}
/* --1440 resolution css start--- */
@media screen and (min-width:1440px) {
    .convotextsec p.customersecrow {

        font-size: 14px;
    }
}
/* --1440 resolution css end--- */
