.addressfromfield .ant-form-item-control.has-success {
    position: relative;
}
.autocomplete-dropdown-container {
    position:absolute;
     top: 100%;
     width: 100%;
     z-index:999;
     box-shadow: 0 2px 7px 0 #0000001f;
}
/* .autocomplete-dropdown-container .suggestion-item:first-child {
    margin-bottom: 8px;
} */
.autocomplete-dropdown-container .suggestion-item {
    margin: 11px;
}
.autocomplete-dropdown-container .suggestion-item--active {
    margin: 11px;
    color: #1e89e4;
    cursor: pointer;
}

.addeditformform {
    padding: 20px 0;
}
.addeditformform .ant-input {
    border: 1px solid #ececec;
}
p.firststepindetification {
    color: #1d89e4;
    border: 1px solid #1d89e4;
    border-radius: 50%;
    /* width: 20px; */
}
p.firststepindetification {
    color: #1d89e4;
    border: 1px solid #1d89e4;
    border-radius: 50%;
    margin: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
}
p.formsubsectiontitle {
    margin-left: 12px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
    display: flex;
    align-items: unset;
    margin-bottom: 15px;
    color: #333232;
}
.addeditformform .ant-form-vertical .ant-form-item {
    margin-bottom: 15px;
    padding-bottom: 0
}
.ant-form-vertical .ant-form-item {
    margin-bottom: 12px;
    padding-bottom: 0
}
.has-error .ant-form-explain {
    position: absolute;
    bottom: -12px;
}
hr.lighborder {
    border: none;
    height: 1px;
    background-color: #d9d9d9;
    margin-bottom: 25px;
    margin-top: 15px;
}
.profiledpview {
    height: 170px;
    /* height: 185px; */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: url('../Images/userplaceholder.png'),#ffffff;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;

}
.inputfilewrapper.onlyeditbtn {
    background: #111;
}
.inputfilewrapper.deleteicon {
    background: #ea0000;
}
.editdeletediv .inputfilewrapper {
    width: 47%;
    padding: 8px 0;
    border-radius: 4px;
    /* margin-top: 0; */
}
.profiledpview.addborderradious {
    border-radius: 5px;
}
.editdeletediv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.inputfilewrapper.onlyeditimg.deleteicon {
    margin-left: 6px;
}
.inputfilewrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 0;
    background-color: #0378e6;
    color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    margin-top: 25px;
    opacity: 0.8;
}
.inputfilewrapper:hover {
    opacity: 1;
}
p.editprofiletext {
    margin: 0;
    font-size: 13.5px;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
    text-transform: unset;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
img.editpencilcss {
    height: 13px;
    width: 13px;
    margin-right: 8px;
}
.inputfilewrapper input[type="file"] {
    position: absolute;
    cursor: pointer;
    right: 0;
    opacity: 0;
    width: 100%;
}

img.profileimgcss {
    height: 100%;
    width: 100%;
    background-color: white;
    object-fit: contain;
    object-position: center;
}
.addeditformform .ant-input {
    border: 1px solid #ececec;
    font-family: 'Roboto',sans-serif;
    font-size: 13px;
    /* margin-bottom: 12px; */
}
.addeditformform .has-error .ant-form-explain, .addeditformform .has-error .ant-form-split {
    color: #f5222d;
    position: absolute;
    bottom: -12px;
    /* bottom: -4px; */
}
.attachmentsection {
    height: 100%;
    min-height: 450px;
    padding: 12px;
    background-color: white;
    border-radius: 4px;
}
.attachmentsection .filepicker.dropzone.dz-clickable {
    background-color: transparent;
    min-height: 400px;
    max-height: 400px;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    border: 2px dashed #f3f3f3;
    overflow-y: auto;
    position: relative;
    text-align: left;
    padding: 5px 0 5px 15px;
}
.attachmentsection .dz-default.dz-message {
    position: absolute;
    left: 0;
    right: 0;
    top: 31%;
}
.attachmentsection .dropzone .dz-preview {
    width: 90px;
    height: 85px;
    margin-top: 0;
    min-height: 85px;
    margin: 10px 10px 32px 2px;
}
.attachmentsection .dropzone .dz-preview .dz-image {
    width: 100%;
    height: 100%;
    border-radius: 3px 3px 0 0;
    border: 1px solid #f1f1f1;
}
.attachmentsection .dropzone .dz-preview:hover a.dz-remove {
   opacity: 1 !important;
}
.attachmentsection .dropzone .dz-preview .dz-details {
    bottom: -25px;
    top: unset;
    left: 0;
    opacity: 1;
    font-size: 10px;
    padding: 4px 0;
    color: rgb(68, 68, 68);
    border: 1px solid #f1f1f1;
}
.attachmentsection .dropzone .dz-preview .dz-details .dz-filename:hover span{
    border: none
}
.attachmentsection .dropzone .dz-preview .dz-details .dz-filename:hover{
    overflow: hidden;
    text-overflow: ellipsis;
}
.attachmentsection .dropzone .dz-preview .dz-details .dz-size{
    display: none
}
.dropzone .dz-preview.dz-image-preview {
    background-color: transparent;
    position: relative;
}
.attachmentsection .dropzone .dz-preview .dz-progress {
    left: 71%;
    width: 51px;
}
.attachmentsection .dropzone .dz-preview .dz-success-mark,.attachmentsection .dropzone .dz-preview .dz-error-mark {
    top: 50%;
    left: 60%;
}
.attachmentsection .dropzone .dz-preview .dz-success-mark svg,.attachmentsection .dropzone .dz-preview .dz-error-mark svg {
    width: 40px;
    height: 53px;
}
.attachmentsection .dropzone .dz-preview .dz-image img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.attachmentsection a.dz-remove {
    opacity: 0 !important;
    /* display: none; */
    font-size: 0 !important;
    position: absolute;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 30px;
    height: 30px;
    background: url(../Images/cancel.png);
    background-size: 14px;
    background-position: center;
    background-color: #eee;
    border-radius: 50%;
    padding: 14px;
    background-repeat: no-repeat;
}
p.firstuploadtext {
    color: #9a9a9a;
    font-family: 'Roboto',sans-serif;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
}
p.seconduploadtext {
    color: #828282;
    font-family: 'Roboto',sans-serif;
    font-size: 10px;
    margin-bottom: 20px;
}
p.dropzonebtn {
    font-family: 'Roboto',sans-serif;
    font-size: 13px;
    background-color: #0378e6;
    color: white;
    width: 50%;
    margin: 0 auto;
    border-radius: 4px;
    padding: 10px 0;
    cursor: pointer;
    opacity: 0.8;
}
p.dropzonebtn:hover {
    opacity: 1;
}
.joblistssec .dropzone .dz-preview.dz-error .dz-error-mark {
    opacity: 1;
    display: none
}
.joblistssec .dz-error-message {
    display: none !important;
}
.addeditbuttongroup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px
}
.buttonComponent_start button.mainbuttoncls.addeditbtncls {
    background-color: #1d89e4;
    color: white;
    text-transform: capitalize;
    font-family: 'Roboto',sans-serif;
    letter-spacing: 0;
    padding: 4px 35px;
    min-width: 0;
}
.buttonComponent_start button.mainbuttoncls.addeditbtncls:hover {
    background: #0064b9;
}
button.buttonactive.mainbuttoncls.addeditbtncls.cancelbtncss {
    background: transparent;
    color: #6d6d6d;
    margin-right: 15px;
}
button.buttonactive.mainbuttoncls.addeditbtncls.cancelbtncss:hover {
    background: #dad8d8;
    opacity: 1;
    margin-right: 15px;
}
p.formsubsectiontitle span {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 1px solid #0378e6;
    display: inline-block;
    text-align: center;
    margin-right: 6px;
    color: #1d89e4;
}
.addeditformform.editcustomer-form .profiledpview {
    height: 174px;
}
.addeditformform.editcustomer-form .ant-calendar-picker,
.addeditformform.editcustomer-form .ant-time-picker {
    width: 100% !important;
}
/* .addeditformform.editcustomer-form.editcompanyform .profiledpview {
    height: 171px;
} */
.addeditformform.editcustomer-form .selectfielditem .ant-select-selection__rendered {
    font-size: 12px;
}

@media screen and (min-width:320px) and (max-width:768px){
    .addeditformform.editcustomer-form .profiledpuploader {
        margin-bottom: 35px;
    }
    .profiledpuploader {
        margin-bottom: 35px;
    }
    p.dropzonebtn {
        width: 73%;
    }
}
.addMainDiv {
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 43px 4px 40px;
    background-color: #0378e6;
    color: #fff;
    overflow: hidden;
    cursor: pointer;
    opacity: 0.8;
    border-radius: 4px;
}
.addNewMainDiv{
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 43px 4px 40px;
    background-color: #0378e6;
    color: #fff;
    overflow: hidden;
    cursor: pointer;
    opacity: 0.8;
    border-radius: 4px;
}
.radioStyle.ant-radio-wrapper{
    height: 30px;
line-height: 30px;
}
.radioCls.ant-radio-wrapper {
    margin-top: 26px;
}
.AddNewFieldTxt {
    margin: 0;
}
.addIconCls{
    margin-top: 0 !important;
}
.AddBtnCls{
    margin-top: 26px;
    cursor: pointer;
}
.disableBtn{
    background-color: #0378e64f;
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 43px 4px 40px;
    color: #fff;
    overflow: hidden;
    cursor: no-drop;
    opacity: 0.8;
    border-radius: 4px;
    pointer-events: none;
}
.disableNewBtn{
    margin-top: 0;
    background-color: #0378e64f;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 43px 4px 40px;
    color: #fff;
    overflow: hidden;
    cursor: no-drop;
    opacity: 0.8;
    border-radius: 4px;
    pointer-events: none;
}
.ant-radio-checked .ant-radio-inner {
    border-color: #1890ff;
    /* height: 17px; */
    /* width: 17px; */
}
.TypeFieldInput .MuiFormControl-root-30.textfieldclass {
    width: 100%;
    background-color: white;
    height: 32px;
}
.TypeFieldInput #outlined-basic {
    height: 0px;
    border: none;
    font-family: 'Roboto',sans-serif;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
    background-color: white;
    padding: 16px 14px;
}
.TypeFieldInput .MuiOutlinedInput-root-34 .MuiOutlinedInput-notchedOutline-41 {
    border: none;
}
.TypeFieldInput .MuiOutlinedInput-input-42{
    padding: 15.5px 14px;
}
.TypeFieldInput .MuiFormControl-root-127.textfieldclass .MuiOutlinedInput-input-94 {
    width: 100%;
}
.TypeFieldInput .MuiOutlinedInput-root-131 .MuiOutlinedInput-notchedOutline-138{
    border: none;
}
.TypeFieldInput .textfieldclass{
    width: 100%;
}
.TypeFieldInput fieldset{
    border: none;
}
.errorMsqForSalesMane {
    font-size: 11px;
    color: red;
    position: absolute;
    top: 5em;
}
.errorMsqForSalesMane.requiredField {
    top: -1px;
    left: 4.5em;
    font-size: 14px;
    color: #ed787e;
}