#loader {  
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }
  @keyframes loader {
    0% { left: -100px }
    100% { left: 110%; }
  }
  #box {
    width: 200px;
    height: auto;
    /* position: absolute; */
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    }
  
  .preloadericon {
    width: 100%;
    height: 100%;
  }
  .field.homecontactinput{
    margin: 0 ;
  }
  .homepage_overlay {
    background-color: #fffffffa;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 999;
    transition: all .1s ease-in-out;
    overflow: hidden;
  
  }
  .homepage_overlay {
    -moz-animation: cssAnimation 0s ease-in 5s forwards;
    -webkit-animation: cssAnimation 0s ease-in 5s forwards;
    -o-animation: cssAnimation 0s ease-in 5s forwards;
    animation: cssAnimation 0s ease-in 5s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  @keyframes cssAnimation {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
  }
  @-webkit-keyframes cssAnimation {
    to {
        width:0;
        height:0;
        visibility:hidden;
    }
  }
  .loading {
    background-color: #cecece;
    height: 6px;
    overflow: hidden;
    position: relative;
    width: 14em;
    border-radius: 5px
  }
  .extracls{
    z-index: 9999999;
  }
  
  .loading-bar {
    animation: side2side 2s ease-in-out infinite;
    background-color: #2196f3;
    height: 100%;
    position: absolute;
    width: 50%;
    border-radius: 5px
  }
  
  .center {
    left: 2px;
    margin: 20px auto 0;
    /* position: absolute; */
    top: 65px;
    right: 0;
  }
  
  @keyframes side2side {
    0%, 100% { transform: translateX(-50%); }
    50%      { transform: translateX(150%); }
  }
  

  