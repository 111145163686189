body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.createconversation .conversationcard .customercardstart {
    padding: 0;
    display: block;
}
.createconversation .conversationcard .customercardstart:hover{
    background-color: white !important
}
.createconversation .customerimagediv {
    height: 67px;
    width: 75px;
    margin: 0; 
}
.convoscendddiv .forbetterview {
    display: flex;
    align-items: flex-start;
}
.convofristdiv {
    padding: 12px;
}
.convotextsec {
    padding-left: 14px;
    padding-top: 3px;
    width: 100%;
}
.convoscendddiv {
    /* padding: 0 12px; */
    position: relative;
    border-top: 1px solid #d9d9d9;
    padding-top: 20px;
    margin-top: 25px;
}
.conversationhappeneddiv {
    padding: 0 12px;
}
.conversationhappeneddiv .convoscendddiv.convothirddiv:first-child {
    border: none;
    margin-top: 0;
    padding-top: 0;
}
.alreadysharedbutton {
    color: #85807d;
    font-size: 14px;
    position: absolute;
    top: 8px;
    right: 15px;
}

span.customersecvalue.datevalueconvo {
    font-size: 11px;
}
.createconversation .attachedfile .customerimagediv {
    height: 60px;
    width: 60px;
    margin: 0;
    margin-right: 8px;
    margin-top: 8px;
    position: relative;
}
.createconversation .attachedfile .customerimagediv:hover .downloadimgdiv {
    opacity: 1;
    width: 100%;
    height: 100%;
    background: #444444a1;
}
.downloadimgdiv {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}
.dropzone.dz-started .dz-message {
    display: none;
}
.dropzone .dz-preview {
    display: inline-block;
    vertical-align: top;

}
.dropzone .dz-message{
    text-align: center;
    margin: 2em 0;
}
img.downloadimg {
  
    width: 22px;
    height: auto;
}
.conversation-replysection {
    padding: 0 12px 12px;
    background-color: #fff;
}
.onlyforaddconversation.conversation-replysection {
    background: transparent;
    margin-top: 15px;
    padding-top: 23px;
    border-top: 1px solid #d0d0d0;
}
.onlyforaddconversation .addtitleform .ant-form-item-label label {
    display: inline-block;
}
.onlyforaddconversation .addtitleform .ant-form-item-label label:before {
    position: absolute;
    right: -10px;
}
.Jobberclock_dashboard .iner-content .conversation-replysection h3 {
    font-size: 15px;
}
.createconversation .conversation-replysection .customerimagediv {
    width: 70px;
    height: 60px;
    margin: 0;
    margin-right: 12px;

}
.conversation-replysection textarea.ant-input {
    background-color: #f6f6f6;
    font-size: 12px;
    color: #5d5d5d;
}
.onlyforaddconversation  textarea.ant-input {
    background-color: #fff;
}
.conversationformstart {
    display: flex;
    align-items: flex-start;
}
.ant-row.ant-form-item.addtitleform {
    margin-bottom: 10px;
    
}
.createconversationformstart{
    display: flex;
    align-items: flex-end
}
.replyleftdiv {
    display: flex;
    align-items: flex-start;
}
.replylefftmaindiv,.replyrighttmaindiv {
    width: 100%;
}
.coversationtextareadiv .ant-row.ant-form-item,.coversationtextareadiv {
    width: 100%;
    margin-bottom: 0;
}

.ant-row.ant-form-item.coversationdropzondiv {
    width: 100%;
    padding: 0;
margin: 0
}
/* .conversationbtndiv {
    margin-left: 5em;
} */
.conversationbtndiv .ant-form-item {
    margin: 0;
}
.replyrighttmaindiv p.formsubsectiontitle {
    margin: 0;
    margin-left: 12px;
}
.replyrighttmaindiv .attachmentsection .filepicker {
    min-height: 190px !important;
    max-height:190px !important
}
.onlyforaddconversation .replyrighttmaindiv .attachmentsection .filepicker {
    min-height: 244px !important;
    overflow-y: auto;
    background: #fff;
    
    max-height: 244px !important;
}
.createconversation h3.heding.createconverstaintitle {
    padding: 0;
    margin: 0;
}
.onlyforaddconversation .replyrighttmaindiv p.formsubsectiontitle {
    margin: 0;
    margin-left: 0;
    margin-bottom: 8px;
}
h3.writecommnetheading {
    font-size: 14px !important;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
    display: flex;
    /* align-items: unset; */
    padding: 0 !important;
    margin-bottom: 7px !important;
    color: #333232 !important;
}
.conversationbtndiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}
.onlyforaddconversation button.editbtndesign {
    margin-right: 0;
}
.conversationchkbox span {
    color: #737373;
    font-size: 13px;
}
.addtitleform .ant-form-item-label {
    padding-bottom: 2px !important;
}
.addtitleform .ant-form-item-label label {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
    display: flex;
    align-items: unset;
    margin-bottom: 7px;
    color: #333232;
}
.attachmentsection {
    height: 100%;
    padding: 12px;
    background-color: white;
    border-radius: 4px;
}
.replyrighttmaindiv p.dropzonebtn {
    width: 33%;
}
.replyrighttmaindiv p.firstuploadtext {
    font-size: 15px;
}
.attachmentsection .filepicker.dropzone.dz-clickable {
    background-color: transparent;
    min-height: 400px;
    max-height: 400px;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    border: 2px dashed #f3f3f3;
    overflow-y: auto;
    position: relative;
    text-align: left;
    padding: 5px 0 5px 15px;
}
.replyrighttmaindiv .attachmentsection .dz-default.dz-message {
    position: absolute;
    left: 0;
    right: 0;
    top: 8% !important;
}
.onlyforaddconversation .attachmentsection .dz-default.dz-message {
    top: 19% !important;
}

.buttonComponent_start.returnpreviousbtn button.buttonactive.mainbuttoncls {
    background-color: transparent;
    padding: 1px 8px;
    text-transform: capitalize;
    color: #2d2d2d;
    border: 1px solid #bec1c5;
    opacity: 0.8;
    background: #bec1c5;
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttonComponent_start.returnpreviousbtn button.buttonactive.mainbuttoncls:hover{
    opacity: 1;
}
.returnpreviousbtn img.buttonimgcls {
    margin-right: 5px;
    width: 12px;    
}


.attachmentsection a.dz-remove {
    opacity: 0 !important;
    /* display: none; */
    font-size: 0 !important;
    position: absolute;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 30px;
    height: 30px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAALVBMVEUAAAD/Q0P/RET/RUX/Q0P/RET/RET/RET/QED/RET/Q0P/QED/RET/REQAAAAgFLt/AAAADXRSTlMAbvG6E7ttzxDL0BTwNoq//wAAAAFiS0dEAIgFHUgAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAR0FEyyL7rpcAAAAq0lEQVQoz2MQMmFAAq5qDLrXHRB8ltpLDLZ3SxAC7ncvM3TevbUBxueYC5QFEqthAp13QfoRSsAKGJCUQBQglEAVIJTAFMCUwBXAlCAUQJQgKYAoQVYAcvL1WmQvgJSgKACZgqIALLCaAU0LwtNYDAW5ATlcwI5EDheII5GUQByJUALzBVwJzBcwJQhvQpUgvAlRghwOYCXI4QBWEoselWiRfYchCSU5OKsBAOqfgkn/EE2FAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTAxLTI5VDA1OjE5OjQ0KzAwOjAwgogVQwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wMS0yOVQwNToxOTo0NCswMDowMPPVrf8AAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC);
    background-size: 14px;
    background-position: center;
    background-color: #eee;
    border-radius: 50%;
    padding: 14px;
    background-repeat: no-repeat;
}
@media screen and (min-width:320px) and (max-width:768px){
    .conversationformstart,.createconversationformstart{
        display: unset
    }
    .replylefftmaindiv, .replyrighttmaindiv{
        width: 100%
    }
    /* .conversationbtndiv{
        margin: 0
    } */
    .replyrighttmaindiv p.dropzonebtn {
        width: 48%;
    }
    
    
    .replyrighttmaindiv p.firstuploadtext{
        font-size: 15px
    }
    .onlyforaddconversation.conversation-replysection {
        padding-left: 0;
        padding-right: 0;
    }
}
/* --1440 resolution css start--- */
@media screen and (min-width:1440px) {
    .convotextsec p.customersecrow {

        font-size: 14px;
    }
}
/* --1440 resolution css end--- */

.addressfromfield .ant-form-item-control.has-success {
    position: relative;
}
.autocomplete-dropdown-container {
    position:absolute;
     top: 100%;
     width: 100%;
     z-index:999;
     box-shadow: 0 2px 7px 0 #0000001f;
}
/* .autocomplete-dropdown-container .suggestion-item:first-child {
    margin-bottom: 8px;
} */
.autocomplete-dropdown-container .suggestion-item {
    margin: 11px;
}
.autocomplete-dropdown-container .suggestion-item--active {
    margin: 11px;
    color: #1e89e4;
    cursor: pointer;
}

.addeditformform {
    padding: 20px 0;
}
.addeditformform .ant-input {
    border: 1px solid #ececec;
}
p.firststepindetification {
    color: #1d89e4;
    border: 1px solid #1d89e4;
    border-radius: 50%;
    /* width: 20px; */
}
p.firststepindetification {
    color: #1d89e4;
    border: 1px solid #1d89e4;
    border-radius: 50%;
    margin: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
}
p.formsubsectiontitle {
    margin-left: 12px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
    display: flex;
    align-items: unset;
    margin-bottom: 15px;
    color: #333232;
}
.addeditformform .ant-form-vertical .ant-form-item {
    margin-bottom: 15px;
    padding-bottom: 0
}
.ant-form-vertical .ant-form-item {
    margin-bottom: 12px;
    padding-bottom: 0
}
.has-error .ant-form-explain {
    position: absolute;
    bottom: -12px;
}
hr.lighborder {
    border: none;
    height: 1px;
    background-color: #d9d9d9;
    margin-bottom: 25px;
    margin-top: 15px;
}
.profiledpview {
    height: 170px;
    /* height: 185px; */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABGlBMVEUAAADY2NjR1NrQ1drQ1drQ1dvQ1dvQ1dzQ1dzR1tzV1dXQ193P1NvQ1dvQ1dvR1tvN1dzG4+PP1drQ1dvQ1dvQ1Nzf39/Q1dvP1trT09vP1drQ1dvO1NnQ1dvQ1dvR1dzP1t3Q1dvQ1dvI29vQ1tzP1dvQ1dvQ1dvQ1dvQ1dvP1dvQ1dvR0dHb29vQ1dvQ1dvS0trR1drR1tvQ1dvQ1dvP1dvQ1drQ1dvV1d/Q1dvT097Q2dnR1trQ1dvQ1dvQ1dvP1dv////O1trR1dvQ1dvR1dzR1Nuq///R1tzQ1dzQ1dvR1dvR1dvQ1tvR1dvN09rQ1du/v7/Q1dvP1NvQ1NrR1dvR2NjQ1NzQ1dvR1tvP1dvQ1dvQ1dsAAADDfFV+AAAAXHRSTlMADVmZw9n0wphYDCab9vWaJAmL+vmJCNLRIzDoL9DTiiWc9w5X3Nvz8trBVgsHz+ciN2SBnrbKzBj9FxtvxP7VgAFEqvy7TQNe2NfWVLnAKXgEoqF9eiGDzcvs63S+ejwAAAABYktHRACIBR1IAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5AEUDQcbbubWNAAAAZRJREFUWMPtlsdCwkAQQJeEhBapoQkKImIBpYPYsYEde5v//w6Tg5GS3Z0Ej3n39zYFZkIIFZcguiVZljxen59YJ6AsgEEwFLaoR6IxmECNJ6z44STMkErj/cUMmJDJos839bUC8hoSS0BhOYIKRIFKDuMHYvSAmkcEVoCBwvddQVagsMoNCMCkyA2I7IDIDbjZAQ83sMYOlLgBmR2QuYF1dmCDG9hkByRuwMMObHEDXnbAyw342IEyN1BhvoYgYryG5rsDQvIq3VdRMylOD8QxPtneoflJ3EgjacpQrdZwPiFZ00IVPda1a0jN+kvo83USuflWm0Y+NL5cRQt7zcBf/F3vZTvr3YFU6o1mq93plkrdTrvVbNQrFuRdQZF607+jnqQIexh7/+DwiPpvPD6pse3+6dk5MOldXPap+mAoAYKr4cD89PI1Rte5uTXx03dYXef+Yfr4UcGKr31pjCYexeOTNV3nefxJvFj3AV7//FrMTkB9MwLvdnyAphH4sBf4NAJf9gLfRsCeD+AEnIAT+MfAD9gMAySA/gGJAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTAxLTIwVDEzOjA3OjI3KzAwOjAwStnZXwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wMS0yMFQxMzowNzoyNyswMDowMDuEYeMAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC),#ffffff;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;

}
.inputfilewrapper.onlyeditbtn {
    background: #111;
}
.inputfilewrapper.deleteicon {
    background: #ea0000;
}
.editdeletediv .inputfilewrapper {
    width: 47%;
    padding: 8px 0;
    border-radius: 4px;
    /* margin-top: 0; */
}
.profiledpview.addborderradious {
    border-radius: 5px;
}
.editdeletediv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.inputfilewrapper.onlyeditimg.deleteicon {
    margin-left: 6px;
}
.inputfilewrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 0;
    background-color: #0378e6;
    color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    margin-top: 25px;
    opacity: 0.8;
}
.inputfilewrapper:hover {
    opacity: 1;
}
p.editprofiletext {
    margin: 0;
    font-size: 13.5px;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
    text-transform: unset;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
img.editpencilcss {
    height: 13px;
    width: 13px;
    margin-right: 8px;
}
.inputfilewrapper input[type="file"] {
    position: absolute;
    cursor: pointer;
    right: 0;
    opacity: 0;
    width: 100%;
}

img.profileimgcss {
    height: 100%;
    width: 100%;
    background-color: white;
    object-fit: contain;
    object-position: center;
}
.addeditformform .ant-input {
    border: 1px solid #ececec;
    font-family: 'Roboto',sans-serif;
    font-size: 13px;
    /* margin-bottom: 12px; */
}
.addeditformform .has-error .ant-form-explain, .addeditformform .has-error .ant-form-split {
    color: #f5222d;
    position: absolute;
    bottom: -12px;
    /* bottom: -4px; */
}
.attachmentsection {
    height: 100%;
    min-height: 450px;
    padding: 12px;
    background-color: white;
    border-radius: 4px;
}
.attachmentsection .filepicker.dropzone.dz-clickable {
    background-color: transparent;
    min-height: 400px;
    max-height: 400px;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    border: 2px dashed #f3f3f3;
    overflow-y: auto;
    position: relative;
    text-align: left;
    padding: 5px 0 5px 15px;
}
.attachmentsection .dz-default.dz-message {
    position: absolute;
    left: 0;
    right: 0;
    top: 31%;
}
.attachmentsection .dropzone .dz-preview {
    width: 90px;
    height: 85px;
    margin-top: 0;
    min-height: 85px;
    margin: 10px 10px 32px 2px;
}
.attachmentsection .dropzone .dz-preview .dz-image {
    width: 100%;
    height: 100%;
    border-radius: 3px 3px 0 0;
    border: 1px solid #f1f1f1;
}
.attachmentsection .dropzone .dz-preview:hover a.dz-remove {
   opacity: 1 !important;
}
.attachmentsection .dropzone .dz-preview .dz-details {
    bottom: -25px;
    top: unset;
    left: 0;
    opacity: 1;
    font-size: 10px;
    padding: 4px 0;
    color: rgb(68, 68, 68);
    border: 1px solid #f1f1f1;
}
.attachmentsection .dropzone .dz-preview .dz-details .dz-filename:hover span{
    border: none
}
.attachmentsection .dropzone .dz-preview .dz-details .dz-filename:hover{
    overflow: hidden;
    text-overflow: ellipsis;
}
.attachmentsection .dropzone .dz-preview .dz-details .dz-size{
    display: none
}
.dropzone .dz-preview.dz-image-preview {
    background-color: transparent;
    position: relative;
}
.attachmentsection .dropzone .dz-preview .dz-progress {
    left: 71%;
    width: 51px;
}
.attachmentsection .dropzone .dz-preview .dz-success-mark,.attachmentsection .dropzone .dz-preview .dz-error-mark {
    top: 50%;
    left: 60%;
}
.attachmentsection .dropzone .dz-preview .dz-success-mark svg,.attachmentsection .dropzone .dz-preview .dz-error-mark svg {
    width: 40px;
    height: 53px;
}
.attachmentsection .dropzone .dz-preview .dz-image img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.attachmentsection a.dz-remove {
    opacity: 0 !important;
    /* display: none; */
    font-size: 0 !important;
    position: absolute;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 30px;
    height: 30px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAALVBMVEUAAAD/Q0P/RET/RUX/Q0P/RET/RET/RET/QED/RET/Q0P/QED/RET/REQAAAAgFLt/AAAADXRSTlMAbvG6E7ttzxDL0BTwNoq//wAAAAFiS0dEAIgFHUgAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAR0FEyyL7rpcAAAAq0lEQVQoz2MQMmFAAq5qDLrXHRB8ltpLDLZ3SxAC7ncvM3TevbUBxueYC5QFEqthAp13QfoRSsAKGJCUQBQglEAVIJTAFMCUwBXAlCAUQJQgKYAoQVYAcvL1WmQvgJSgKACZgqIALLCaAU0LwtNYDAW5ATlcwI5EDheII5GUQByJUALzBVwJzBcwJQhvQpUgvAlRghwOYCXI4QBWEoselWiRfYchCSU5OKsBAOqfgkn/EE2FAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTAxLTI5VDA1OjE5OjQ0KzAwOjAwgogVQwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wMS0yOVQwNToxOTo0NCswMDowMPPVrf8AAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC);
    background-size: 14px;
    background-position: center;
    background-color: #eee;
    border-radius: 50%;
    padding: 14px;
    background-repeat: no-repeat;
}
p.firstuploadtext {
    color: #9a9a9a;
    font-family: 'Roboto',sans-serif;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
}
p.seconduploadtext {
    color: #828282;
    font-family: 'Roboto',sans-serif;
    font-size: 10px;
    margin-bottom: 20px;
}
p.dropzonebtn {
    font-family: 'Roboto',sans-serif;
    font-size: 13px;
    background-color: #0378e6;
    color: white;
    width: 50%;
    margin: 0 auto;
    border-radius: 4px;
    padding: 10px 0;
    cursor: pointer;
    opacity: 0.8;
}
p.dropzonebtn:hover {
    opacity: 1;
}
.joblistssec .dropzone .dz-preview.dz-error .dz-error-mark {
    opacity: 1;
    display: none
}
.joblistssec .dz-error-message {
    display: none !important;
}
.addeditbuttongroup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px
}
.buttonComponent_start button.mainbuttoncls.addeditbtncls {
    background-color: #1d89e4;
    color: white;
    text-transform: capitalize;
    font-family: 'Roboto',sans-serif;
    letter-spacing: 0;
    padding: 4px 35px;
    min-width: 0;
}
.buttonComponent_start button.mainbuttoncls.addeditbtncls:hover {
    background: #0064b9;
}
button.buttonactive.mainbuttoncls.addeditbtncls.cancelbtncss {
    background: transparent;
    color: #6d6d6d;
    margin-right: 15px;
}
button.buttonactive.mainbuttoncls.addeditbtncls.cancelbtncss:hover {
    background: #dad8d8;
    opacity: 1;
    margin-right: 15px;
}
p.formsubsectiontitle span {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 1px solid #0378e6;
    display: inline-block;
    text-align: center;
    margin-right: 6px;
    color: #1d89e4;
}
.addeditformform.editcustomer-form .profiledpview {
    height: 174px;
}
.addeditformform.editcustomer-form .ant-calendar-picker,
.addeditformform.editcustomer-form .ant-time-picker {
    width: 100% !important;
}
/* .addeditformform.editcustomer-form.editcompanyform .profiledpview {
    height: 171px;
} */
.addeditformform.editcustomer-form .selectfielditem .ant-select-selection__rendered {
    font-size: 12px;
}

@media screen and (min-width:320px) and (max-width:768px){
    .addeditformform.editcustomer-form .profiledpuploader {
        margin-bottom: 35px;
    }
    .profiledpuploader {
        margin-bottom: 35px;
    }
    p.dropzonebtn {
        width: 73%;
    }
}
.addMainDiv {
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 43px 4px 40px;
    background-color: #0378e6;
    color: #fff;
    overflow: hidden;
    cursor: pointer;
    opacity: 0.8;
    border-radius: 4px;
}
.addNewMainDiv{
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 43px 4px 40px;
    background-color: #0378e6;
    color: #fff;
    overflow: hidden;
    cursor: pointer;
    opacity: 0.8;
    border-radius: 4px;
}
.radioStyle.ant-radio-wrapper{
    height: 30px;
line-height: 30px;
}
.radioCls.ant-radio-wrapper {
    margin-top: 26px;
}
.AddNewFieldTxt {
    margin: 0;
}
.addIconCls{
    margin-top: 0 !important;
}
.AddBtnCls{
    margin-top: 26px;
    cursor: pointer;
}
.disableBtn{
    background-color: #0378e64f;
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 43px 4px 40px;
    color: #fff;
    overflow: hidden;
    cursor: no-drop;
    opacity: 0.8;
    border-radius: 4px;
    pointer-events: none;
}
.disableNewBtn{
    margin-top: 0;
    background-color: #0378e64f;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 43px 4px 40px;
    color: #fff;
    overflow: hidden;
    cursor: no-drop;
    opacity: 0.8;
    border-radius: 4px;
    pointer-events: none;
}
.ant-radio-checked .ant-radio-inner {
    border-color: #1890ff;
    /* height: 17px; */
    /* width: 17px; */
}
.TypeFieldInput .MuiFormControl-root-30.textfieldclass {
    width: 100%;
    background-color: white;
    height: 32px;
}
.TypeFieldInput #outlined-basic {
    height: 0px;
    border: none;
    font-family: 'Roboto',sans-serif;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
    background-color: white;
    padding: 16px 14px;
}
.TypeFieldInput .MuiOutlinedInput-root-34 .MuiOutlinedInput-notchedOutline-41 {
    border: none;
}
.TypeFieldInput .MuiOutlinedInput-input-42{
    padding: 15.5px 14px;
}
.TypeFieldInput .MuiFormControl-root-127.textfieldclass .MuiOutlinedInput-input-94 {
    width: 100%;
}
.TypeFieldInput .MuiOutlinedInput-root-131 .MuiOutlinedInput-notchedOutline-138{
    border: none;
}
.TypeFieldInput .textfieldclass{
    width: 100%;
}
.TypeFieldInput fieldset{
    border: none;
}
.errorMsqForSalesMane {
    font-size: 11px;
    color: red;
    position: absolute;
    top: 5em;
}
.errorMsqForSalesMane.requiredField {
    top: -1px;
    left: 4.5em;
    font-size: 14px;
    color: #ed787e;
}
.extradetailssection {
    padding: 16px;
}

.ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}
.attachedfileviewdiv {
    display: flex;
    align-items: center;
}
.attachedfilegroup {
    height: 28px;
    width: 28px;
    margin-left: 2px;
    margin-right: 3px;
}
img.attachedfileimg {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
p.nodetailstext {
    margin: 0;
    text-align: center;
    color: #b1b1b1;
    font-size: 15px;
    padding: 20px;
    background-color: #f7f7f7;
}

button.mainbuttoncls {
    color: #fff;
    background-color: #1e87e4;
    padding: 8px 35px;
    /* width: 100%; */
    font-size: 13px;
}
button.mainbuttoncls.customermapbtn{
    display: none;
}
button.mainbuttoncls.customer-callbtn{
    display: none;
}
.buttonprogressdesign {
    color: #fff;
}

button.mainbuttoncls:hover {
    background-color: #006ac3;
}

img.buttonimgcls {
    width: 13px;
    height: auto;
}

.phonefieldinput input {
    width: 100%;
    border-radius: 3px;
    border: none;
    box-shadow: none;
    height: 32px;
    padding: 6px 10px;
    font-size: 13px;
}
.phonefieldinput input:focus, .phonefieldinput input:hover {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    /* border-right-width: 1px !important; */
    border: 1px solid #40a9ff;
}
.createddropmenu {
    position: absolute;
    right: 4px;
    top: 0;
    cursor: pointer;
    /* background-color: #1d89e4; */
    background-color: transparent;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
}

button.buttoninactive.mainbuttoncls {
    font-size: 16px !important;
    padding: 8px 35px;
    text-transform: capitalize;
    pointer-events: none;
    /* opacity: 0.4; */
    background-color: #d6d6d6;
    color: #616161;
}

button.buttonloading.mainbuttoncls {
    width: 82px;
    height: 29px;
}

span.customersecrow.filesattached {
    color: #1e89e4;
    text-decoration: underline;
    margin-top: 15px;
    font-size: 12px;
}

.customercardstart:hover {
    background: #f6fbff !important;
}

.createconversation .customercardstart:hover {
    background: white
}
.createddropmenu {
    margin-top: 3px;
}
.customercardstart .iconarea.ant-dropdown-trigger {
    width: 22px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: transparent;
    /* margin: 5px 3px 0 0;
    padding: 5px; */
}

a.addresslink span.customersecvalue:hover {
    color: #1e89e4;
}

.customercardstart:hover .createddropmenu {
    background: #eaeaea;
}

.customercardstart .iconarea.ant-dropdown-trigger i.fa.fa-ellipsis-v {
    color: #b5b5b5;
    font-size: 11px;
    margin-top: 1.5px;
}

.customercardstart:hover .iconarea.ant-dropdown-trigger i.fa.fa-ellipsis-v {
    /* color: #f5f5f5; */
}

/*info card css start */

.collasibearrow {
    font-size: 10px;
    margin-left: 3px;
}

p.accordionpara {
    margin: 0;
    font-size: 13px;
    color: #1d89e4;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}

.infocardfooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding: 8px 12px; */
}

.infocardfooterstart {
    background-color: white;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #f3f3f3;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

button.editbtndesign {
    text-transform: capitalize;
    font-size: 13px;
    background-color: #1d89e4;
    color: white;
    margin-right: 16px;
    opacity: 0.8;
    cursor: pointer;
    padding: 4px 30px
}

button.editbtndesign.disablebtn {
    pointer-events: none;
    opacity: 0.4;
}

button.editbtndesign.activebtn {
    cursor: pointer;
    opacity: 1;
}

button.mainbuttoncls.editbtndesign:hover {
    opacity: 1;
}

button.editbtndesign:hover, button.editbtndesign:focus {
    background-color: #1d89e4;
    color: white;
}

.ant-popover.ant-popover-placement-bottom {
    max-width: 9%;
    min-width: 9%;
}

.ant-popover.ant-popover-placement-bottom .ant-popover-inner-content {
    padding: 0;
}

p.deletetext {
    margin: 0;
    padding: 10px 12px 10px;
    cursor: pointer;
    font-size: 13px;
}

p.assigntext:hover, p.deletetext:hover {
    background-color: #1d89e408;
}

p.assigntext {
    margin: 0;
    padding-bottom: 2px;
    border-bottom: 1px solid #eaeaea;
    padding: 10px 12px 10px;
    font-size: 13px;
    cursor: pointer;
}

.customercardstart {
    background-color: white;
    /* border-radius: 4px; */
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    /* border: 1px solid #f3f3f3; */
    margin-top: 12px;
    /* position: relative; */
    /* padding: 12px; */
}

.leftpartsec {
    display: flex;
    width: 87%;
}
.conversationleft{
    border-right: 1px solid #f5f3f3;
    display: unset;
    width: 90%
}
.customerimagediv {
    height: 100px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    overflow: hidden;
    margin: 10px 0 10px 10px;
}

.attachfileinfo {
    display: flex;
    /* align-items: center;
    justify-content: center; */
    flex-flow: column
}
.uploadedfilenametext{
    width: 100%
}
img.customerimg {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

img.documentimgcls {
    height: 65px;
    width: 64px;
    object-fit: contain;
    object-position: center;
}

.customercardstart img.customerimg {
    object-fit: contain;
    background-color: #dbdbdb;
    }
.customercardstart .attachedfile img.customerimg{
    object-fit: contain;
}
p.customernametext {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    color: #142339;
    display: flex;
    align-items: center
}

p.namespan {
    width: 55%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

p.customersectitle {
    margin: 0;
    font-size: 12px;
    color: #638dee;
    font-weight: 500;
}

.leftpart {
    width: 100px;
}

p.customersecrow {
    margin: 5px 0 0;
    font-size: 12px;
    color: #142339;
    font-weight: 400;
}

.rightpart p.customersecrow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
}

span.customersecvalue.emailvalue {
    color: #4a88e9;
}

span.customersecvalue {
    color: #797979;
    line-height: 20px
}
.customersecvalue a{
    margin-left: 3px;
}
span.customerseclabel {
    font-weight: 500;
    line-height: 20px
}

/* .customersecondsec,.customerfirstsec,.customerthirdsec,.customerlastsec {
    padding: 10px;
} */

.customertypechip {
    /* background-color: transparent; */
    font-size: 11px;
    color: white;
    padding: 3px 5px;
    border-radius: 4px;
    margin-left: 3px;
    text-transform: uppercase
}

.aqtag {
    background-color: #53d266;
}

.completed {
    background-color: #53d266;
}

.cancelled {
    background-color: #f84b3f;
}

.notstarted {
    background-color: #ababab;
}

.inprogress {
    background-color: #fbb34d;
}

.apqtag {
    background-color: #fbb34d;
}

.astag {
    background-color: #f84b3f;
}

.asqtag {
    background-color: #129ff7;
}

.customerfirstsec {
    margin: 13px 0 10px 10px;
    padding-right: 24px;
    border-right: 1px solid #f5f3f3;
    width: 100%;
}

.customersecondsec {
    margin: 13px 0 10px 20px;
    padding-right: 20px;
    border-right: 1px solid #ebebeb;
    width: 250px;
}

.customerthirdsec {
    margin: 13px 0 10px 20px;
    width: 30%;
}

.customerlastsec {
    padding: 13px 10px 10px 20px;
    background-color: #f9fdff;
    border-left: 1px solid #e8f5fb;
    width: 14%
}

.alignemntdiv {
    display: flex;
    align-items: center
}

.forbetterview {
    display: flex;
}

.opendiv.extradetailssection {
    background-color: white;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 200px;
}

.closediv.extradetailssection {
    height: 0;
}

.opendiv.extradetailssection, .closediv.extradetailssection {
    -webkit-transition-property: height;
    -webkit-transition-duration: 1s;
    transition-property: height;
    transition-duration: 1s;
}

.closediv.extradetailssection .detailsdivstarted {
    height: 0;
    display: none;
}

/* .detailsdivstarted {
    padding: 12px;
    border-top: 1px solid #f3f3f3;
} */

p.detailstitle {
    margin: 0 0 5px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: #1d89e4;
    font-weight: 500;
}

.detailsinfo {
    font-size: 13px;
    color: #9a9a9a;
    font-family: 'Roboto', sans-serif;
}
.detailsinfo .forbetterview {
    width: 100% !important;
}

.customerdetailfooter {
    background-color: white
}

.customerdetailfooter .collasibearrow {
    display: none;
}

.infocardattachfile .customerimagediv {
    width: 30px;
    height: 30px;
    border-radius: 0;
    margin: 0 0 0 10px;
}

.attachedfile.infocardattachfile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    width: 25%;
}

.opacityzero.attachedfile.infocardattachfile {
    opacity: 0.3;
    pointer-events: none;
}

.opacityone.attachedfile.infocardattachfile {
    opacity: 1;
}

.accordiondiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 12px;
    border-top: 1px solid #ebebeb;
}

/* info card css end */

/* --Job Info card css start--- */

.longdesclimit {
    width: 90%
}

.longdesclimit span.customersecvalue {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
}

.alertmodaljobvisibility.alertmodalinner .ant-modal-body {
    text-align: center;
    display: flex;
    align-items: unset;
    justify-content: center;
    flex-direction: column;
    padding: 18px;
}

.alertmodaljobvisibility p.jobvisibilitytext {
    text-align: left;
    font-size: 16px;
    color: #464545;
}

.alertmodaljobvisibility .datetimepickermain {
    /* border: 1px solid #d4d4d4; */
    /* border-radius: 2px; */
    /* font-size: 15px; */
}

.alertmodaljobvisibility .datetimepickermain input[type="text"] {
    font-size: 14px;
    padding: 6px 10px;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
}
.alertmodaljobvisibility .datetimepickermain input[type="text"]:hover {
    border-color: #5291ff;
}

.alertmodaljobvisibility .alegrtfooter {
    padding-bottom: 0;
    padding-top: 15px;
}

.alertmodaljobvisibility button.buttonactive.mainbuttoncls.alertbuttoncls {
    background: #212121;
}

.jobstatuschangeddiv button.ant-switch.ant-switch-checked {
    background-color: #2ea200;
}

.jobstatuschangeddiv {
    width: 56%;
}

.jobstatuschangeddiv p {
    color: #1c2640;
    margin: 0;
    font-size: 13px;
    font-weight: 400;
}

.jobstatuschangeddiv button.ant-switch {
    margin-left: 12px;
}
.infocardfooter button.MuiButtonBase-root-27.MuiButton-root-1.MuiButton-text-3.MuiButton-flat-6.buttonactive.undefined.mainbuttoncls.callbtndesign {
    display:none;
}

.jobinfocard .customersecondsec {
    margin: 13px 0 10px 30px;
    padding-right: 0px;
}

.jobinfocard .customerimagediv {
    /* margin: 0 0 0 10px; */
}

/* .jobinfocard .customerthirdsec {
    width: unset;
} */

span.nystd.customertypechip {
    background-color: #dedede;
    color: #8f8f8f;
}

.jobinfocard .leftpartsec {
    width: 100%;
}

/* --Job info card css end----- */

/* ---Conversation card css start--- */

.rightside button.buttonactive.mainbuttoncls.addconvobutton {
    text-transform: capitalize;
    letter-spacing: 0;
    font-size: 12px;
    background-color: transparent;
    color: #1e89e4;
    padding: 4px 8px;
    border: 1px solid #1e89e4;
    opacity: 0.8;
}

.rightside button.buttonactive.mainbuttoncls.addconvobutton:hover {
    opacity: 1;
}

.leftside {
    /* padding: 10px 20px 10px; */
    /* border-right: 1px solid #f5f3f3; */
}

p.convodateandtime {
    font-size: 12px;
    color: #787878;
    margin: 0;
}

p.convoheading {
    margin: 0;
    color: #233046;
    font-size: 15px;
    font-weight: 500;
    padding: 3px 0 1px;
}

.convosubtext {
    margin: 0;
    font-size: 13px;
    color: #8a8a8a;
    line-height: 23px;
    padding-top: 4px;
}

.showCompanyName {
    margin: 0;
    font-size: 20px;
    color: #241772;
    align-items: center;
}

.conversationcard .customercardstart {
    padding: 12px;
    align-items: center;
}

/* ---Conversation card css end--- */

/* ---Work card css start--- */
.infocardfooter button.MuiButtonBase-root-72.MuiButton-root-46.MuiButton-text-48.MuiButton-flat-51.buttonactive.undefined.mainbuttoncls.callbtndesign{
    display: none;
}

.workcard .forbetterview {
    width: 40%;
}

.workcard .customerimagediv .sb-avatar__text {
    border-radius: 5px;
    opacity: 0.95;
}

.customercardparent .customerimagediv .sb-avatar__text {
    opacity: 0.95;
}

.workcard .forbetterview .customerfirstsec {
    width: 100%;
    border: none;
}
.customerimagediv.formanagerimgdiv {
    width: 100px;
}

.workcard .customersecondsec {
    border-left: 1px solid #ebebeb;
    margin-left: 0;
    padding-left: 49px;
    padding-right: 49px;
    margin-right: 40px;
    width: 30%;
}

/* .workcard .customerimagediv {
    width: 100px;
} */

.joblisttoparea {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.joblistbuttonarea {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cardparentdiv .ant-collapse-header {
    padding: 0 !important;
}

.cardparentdiv .ant-collapse-item.ant-collapse-no-arrow {
    border: none;
}

.cardparentdiv .ant-collapse {
    border: none;
    background-color: white;
    border-radius: 0 0 4px 4px;
}

.cardparentdiv .ant-collapse-content {
    border-top: 1px solid #ebebeb;
}

.forbetterview.workatatchment {
    width: 24%;
}

/* ---Work card css end---- */

/* ---Alert css start---- */

.alertmodalinner .ant-modal-body {
    text-align: center;
}
.alertmodalinner .alegrtfooter{
    padding-bottom: 0;
}
.alertmodalinner .ant-modal-body {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 24px;
}

.alerticondiv {
    background: #fad5d7;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #f04736;
}

p.alertheading {
    margin: 0;
    padding: 16px 0 5px;
    font-size: 18px;
    font-weight: 500;
    color: #374558;
}

p.alertheading p {
    margin: 0;
}

p.alersubtext {
    margin: 0;
    color: #b4b4b4;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 15px;
}

.alertmodalinner .ant-modal-footer {
    display: none;
}

.alegrtfooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 0px 20px;
    /* border-top: 1px solid #eee; */
}

button.buttonactive.mainbuttoncls.cancelbuttoncls {
    background: transparent;
    color: #888888;
    padding: 4px 20px;
    /* margin-right: 11px; */
    text-transform: capitalize;
    font-weight: 400;
    margin-right: 10px;
}

button.buttonactive.mainbuttoncls.cancelbuttoncls:hover {
    background-color: #eee;
}
button.buttonactive.mainbuttoncls.alertbuttoncls {
    background: #f04736;
    padding: 4px 20px;
    /* margin-right: 11px; */
    text-transform: capitalize;
    font-weight: 400;
    opacity: 0.8;
}

button.buttonactive.mainbuttoncls.alertbuttoncls:hover {
    opacity: 1;
}

.alertmodalinner .ant-modal {
    width: 420px !important;
}

.alertmodalinner .ant-modal-close {
    display: none;
}

.alertcloseicon {
    position: absolute;
    right: 5px;
    top: 4px;
    cursor: pointer;
    font-size: 14px;
    opacity: 0.4;
    padding: 5px 7px;
    line-height: 0;
    border-radius: 50%;
}
.alertcloseicon:hover {
    opacity: 0.6;
    background-color: #dadada;
}

/* ---Alert css end---- */

/* ----No data found start--- */

.nodatafound {
    background: #fff;
    height: 455px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    border: 1px solid #d6d6d6;
}

.nodatafounddiv {
    width: 200px;
    height: 200px;
}

img.nodatafoundimg {
    width: 100%;
    height: 100%;
}

p.nodatafoundtext {
    margin: 0;
    /* position: absolute; */
    font-size: 20px;
    color: #75a4fc;
    font-weight: 500;
}

/* ---NO data found end------ */

/* ClientCompanyCard css start */

.leftsidepart {
    display: flex;
    align-items: flex-start;
    width: 34%;
}

.leftsecond-column {
    margin: 13px 0 10px 10px;
    width: 100%;
}

.inner-columnsec {
    display: flex;
    /* width: 150px; */
    /* justify-content: space-between; */
}

.leftfirst-column .customerimagediv {
    width: 100px;
    height: 100px;
}

.leftsidepart, .rightsidepart {
    position: relative;
}

.leftsidepart::after {
    content: '';
    width: 1px;
    height: 85%;
    background: #eee;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
}

.rightsidepart::after {
    content: '';
    width: 1px;
    height: 85%;
    background: #eee;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}

.middlepart .customersecondsec {
    border: none;
    width: unset !important;
}

.middlepart .rightpart p.customersecrow {
    width: 270px;
}

.rightsidepart .customersecondsec {
    width: unset !important;
}

.client-companycard {
    position: relative;
}

.client-companycard .iconarea.ant-dropdown-trigger {
    font-size: 10px;
    color: #b1b1b1;
}

.client-companycard .createddropmenu {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.client-companycard .ant-collapse-item {
    background: #fff !important;
}

.client-companycard .ant-collapse-header {
    padding: 0 !important;
}

.client-companycard .ant-collapse {
    border: none;
}

.client-companycard .ant-collapse-content.ant-collapse-content-active {
    border: none;
    border-top: 1px solid #ebebeb;
}

/* ClientCompanyCard css end */

/* Pagination css start */

.globalpaginationstart {
    margin-top: 20px;
    text-align: right;
}
.forjoblistcls .globalpaginationstart{
    margin-bottom: 30px ;
    margin: 20px 37px 30px;
}
.globalpaginationstart .ant-pagination-item-active, .globalpaginationstart .ant-pagination-item-active a {
    background-color: #1d89e4;
    color: white
}

.globalpaginationstart .ant-pagination-item-active:focus, .globalpaginationstart .ant-pagination-item-active:hover {
    background-color: #1d89e4;
}

.globalpaginationstart .ant-pagination-item-active:focus a, .globalpaginationstart .ant-pagination-item-active:hover a {
    color: white;
}

/* Pagination css end */

/* Converstaion skeleton start */

.conversationskeletondivstart ul.ant-skeleton-paragraph {
    padding: 0;
    margin: 0;
}

.conversationskeletondivstart {
    background-color: white;
    border-radius: 4px;
    padding: 20px 20px;
    margin-bottom: 20px
}
.individualcardskeleton{
    padding: 0
}
.individualcardskeleton .ant-skeleton-header{
    padding-right: 0
}
.individualcardskeleton .ant-skeleton-header .ant-skeleton-avatar{
    width: 100%;
    padding: 4em;
    border-radius: 3px;
}
.individualcardskeleton .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar{
    background: linear-gradient(90deg, #cccccc 25%, #dadada 37%, #d4d4d4 63%) ;
    background-size: 400% 100%;

}
/* Conversation skeleton end */

button.buttonactive.undefined.mainbuttoncls.emailsubmitbutton {
    background-color: #004f94;
    text-transform: capitalize;
    font-size: 15px;
    padding: 4px 25px;
    font-weight: 400;
}

p.emailfieldtext {
    font-size: 14px;
    color: #a0a0a0;
    /* margin: 0; */
}

.emailfieldmodalcls .ant-modal-footer {
    display: none;
}

.emailfieldmodalcls .ant-form-item-label {
    line-height: 25px
}

.emailfieldmodalcls .ant-form-item-label label {
    font-size: 14px;
    color: #525252;
}

.emailfieldmodalcls .ant-modal-body {
    padding: 24px 30px 1px;
    font-size: 14px;
}
.emailfieldmodalcls button.ant-modal-close {
    display: none;
}
.emailfieldmodalcls .alertcloseicon {
    right: 11px;
    font-size: 16px;
    color: #4c4c4c;
    opacity: 0.5;
}
/* Responsive css start */

@media screen and (min-width:320px) and (max-width:768px) {
    .cardparentdiv.jobinfocard.customercardparent .customerfirstsec {
        width: 100%
    }
    .cardparentdiv.jobinfocard.customercardparent .jobstatuschangeddiv {
        width: 100%
    }
    .cardparentdiv.jobinfocard.customercardparent .infocardfooter {
        margin-top: 15px;
        justify-content: flex-start
    }
    .cardparentdiv.jobinfocard.workcard .hidden-xs {
        display: block !important
    }
    .cardparentdiv.jobinfocard.workcard .customersecondsec {
        padding-left: 0;
        border-left: none
    }
    .cardparentdiv.jobinfocard.workcard .createddropmenu {
        right: 7px;
        top: 7px;
    }
    .leftpartsec {
        display: unset;
        width: 100%
    }
    .infocardfooter button.MuiButtonBase-root-72.MuiButton-root-46.MuiButton-text-48.MuiButton-flat-51.buttonactive.undefined.mainbuttoncls.callbtndesign
        {        height: 100%;
        width: 100%;
        border-radius: 0;
        display: block;
        font-size: 14px;
        background: #f89e32;
        text-transform: capitalize;
    }
    .buttonComponent_start .customer-callbtn{
        background-color: #f89e32; 
        height: 45px;
        border-radius: 0;
        margin-top: 0;
    }
    .infocardfooter .buttonComponent_start.joblistfooter-callbtn{
        line-height: 43px;
    }
    button.mainbuttoncls.customer-callbtn{
        display: unset;
    }
    
    .buttonComponent_start.joblistfooter-call {
        line-height: 43px;
    }
    .customercardstart {
        display: unset
    }
    /* .customerimagediv {
        margin: 0;
    } */
    .customerfirstsec {
        margin: 8px 0 2px 10px;
        width: 68%;
        padding: 0;
        border-right: none
    }
    .rightpart p.customersecrow {
        width: 155px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .extradetailssection .forbetterview {
        border: none
    }
    .forbetterview {
        padding-bottom: 20px;
        border-bottom: 1px solid #ebebeb;
        align-items: center;
    }
    .customersecondsec {
        border-bottom: 1px solid #ebebeb;
        padding-right: 0;
        padding-bottom: 20px;
        border-right: none;
        margin-left: 0;
        width: 100%;
    }
    .cardparentdiv {
        margin-bottom: 15px;
        box-shadow: 0 1px 20px #00000017;
        padding: 12px 12px 12px;
        background-color: white;
        position: relative;
    }
    .customerthirdsec {
        margin-left: 0;
        border-bottom: none;
        width: 100%;
        padding-bottom: 0;
    }
    .customercardstart {
        border: none
    }
    .customerlastsec {
        border: none;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .extradetailssection {
        padding: 0;
    }
    .viewhistoryform.customerhistorypage .ant-collapse-content > .ant-collapse-content-box {
        padding-left: 0;
        padding-right: 0;
    }
    .customercard-mobileview .forbetterview {
        padding-bottom: 0px;
        border-bottom: none;
    }
    .customercardstart.customercard-mobileview {
        display: block;
        margin-top: 0;
        /* border-bottom: 1px solid #f1f0f0; */
        border-radius: 0;
        /* padding-bottom: 12px; */
        /* margin-bottom: 5px; */
    }
    .customercard-mobileview .createddropmenu {
        right: 0;
    }
    .customerimagediv.formanagerimgdiv{
        width:50px
        }
    .infocardfooterstart {
        border-width: 0;
    }
    .attachedfile.infocardattachfile {
        display: none
    }
    .accordiondiv {
        justify-content: flex-end;
    }
    /* Worker Info card responsive start */
    .workcard .forbetterview {
        width: 100%;
    }
    .cardparentdiv.jobinfocard.workcard .forbetterview {
        padding-bottom: 14px;
    }
    .cardparentdiv {
        position: relative;
    }
    .workcard .customercardstart {
        display: block;
        margin-top: 0;
    }
    .workcard .createddropmenu {
        right: -7px;
        top: -7px;
    }
    /* Worker Info card responsive end */
    /* .leftsidepart {
        width: 100%;
    } */
}

/* --1440 resolution css start--- */

@media screen and (min-width:1440px) {
    p.customersecrow {
        font-size: 14px;
    }
    p.customersectitle {
        font-size: 14px;
    }
    span.customersecrow.filesattached {
        font-size: 13px;
    }
    .leftpart {
        width: 110px;
    }
    .customersecondsec{
        width: 270px;
    }
    .leftpart {
        width: 115px;
    }
}

/*--1440 resolution css end--- */

@media screen and (min-width:768px) and (max-width:1023px) {
    .phonefieldinput input{
        border: 1px solid #ececec;
    border-radius: 2px;
    }
    .addjob i.anticon.anticon-plus {
        font-weight: 900;
        font-size: 9px;
    }
    /* .workcard .forbetterview .customerfirstsec{
        width: 67% !important;
    } */
    /* .addjob i.anticon.anticon-plus{
        font-size: 16px !important;
    } */
}
@media screen and (min-width:1024px) and (max-width:1366px){
    .addjob i.anticon.anticon-plus {
        font-weight: 900;
        font-size: 11px;
    }
}

/* Responsive css end */
#loader {  
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }
  @keyframes loader {
    0% { left: -100px }
    100% { left: 110%; }
  }
  #box {
    width: 200px;
    height: auto;
    /* position: absolute; */
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    }
  
  .preloadericon {
    width: 100%;
    height: 100%;
  }
  .field.homecontactinput{
    margin: 0 ;
  }
  .homepage_overlay {
    background-color: #fffffffa;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 999;
    transition: all .1s ease-in-out;
    overflow: hidden;
  
  }
  .homepage_overlay {
    animation: cssAnimation 0s ease-in 5s forwards;
    animation-fill-mode: forwards;
  }
  @keyframes cssAnimation {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
  }
  .loading {
    background-color: #cecece;
    height: 6px;
    overflow: hidden;
    position: relative;
    width: 14em;
    border-radius: 5px
  }
  .extracls{
    z-index: 9999999;
  }
  
  .loading-bar {
    animation: side2side 2s ease-in-out infinite;
    background-color: #2196f3;
    height: 100%;
    position: absolute;
    width: 50%;
    border-radius: 5px
  }
  
  .center {
    left: 2px;
    margin: 20px auto 0;
    /* position: absolute; */
    top: 65px;
    right: 0;
  }
  
  @keyframes side2side {
    0%, 100% { transform: translateX(-50%); }
    50%      { transform: translateX(150%); }
  }
  

  
