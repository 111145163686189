.extradetailssection {
    padding: 16px;
}

.ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}
.attachedfileviewdiv {
    display: flex;
    align-items: center;
}
.attachedfilegroup {
    height: 28px;
    width: 28px;
    margin-left: 2px;
    margin-right: 3px;
}
img.attachedfileimg {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
p.nodetailstext {
    margin: 0;
    text-align: center;
    color: #b1b1b1;
    font-size: 15px;
    padding: 20px;
    background-color: #f7f7f7;
}

button.mainbuttoncls {
    color: #fff;
    background-color: #1e87e4;
    padding: 8px 35px;
    /* width: 100%; */
    font-size: 13px;
}
button.mainbuttoncls.customermapbtn{
    display: none;
}
button.mainbuttoncls.customer-callbtn{
    display: none;
}
.buttonprogressdesign {
    color: #fff;
}

button.mainbuttoncls:hover {
    background-color: #006ac3;
}

img.buttonimgcls {
    width: 13px;
    height: auto;
}

.phonefieldinput input {
    width: 100%;
    border-radius: 3px;
    border: none;
    box-shadow: none;
    height: 32px;
    padding: 6px 10px;
    font-size: 13px;
}
.phonefieldinput input:focus, .phonefieldinput input:hover {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    /* border-right-width: 1px !important; */
    border: 1px solid #40a9ff;
}
.createddropmenu {
    position: absolute;
    right: 4px;
    top: 0;
    cursor: pointer;
    /* background-color: #1d89e4; */
    background-color: transparent;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
}

button.buttoninactive.mainbuttoncls {
    font-size: 16px !important;
    padding: 8px 35px;
    text-transform: capitalize;
    pointer-events: none;
    /* opacity: 0.4; */
    background-color: #d6d6d6;
    color: #616161;
}

button.buttonloading.mainbuttoncls {
    width: 82px;
    height: 29px;
}

span.customersecrow.filesattached {
    color: #1e89e4;
    text-decoration: underline;
    margin-top: 15px;
    font-size: 12px;
}

.customercardstart:hover {
    background: #f6fbff !important;
}

.createconversation .customercardstart:hover {
    background: white
}
.createddropmenu {
    margin-top: 3px;
}
.customercardstart .iconarea.ant-dropdown-trigger {
    width: 22px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: transparent;
    /* margin: 5px 3px 0 0;
    padding: 5px; */
}

a.addresslink span.customersecvalue:hover {
    color: #1e89e4;
}

.customercardstart:hover .createddropmenu {
    background: #eaeaea;
}

.customercardstart .iconarea.ant-dropdown-trigger i.fa.fa-ellipsis-v {
    color: #b5b5b5;
    font-size: 11px;
    margin-top: 1.5px;
}

.customercardstart:hover .iconarea.ant-dropdown-trigger i.fa.fa-ellipsis-v {
    /* color: #f5f5f5; */
}

/*info card css start */

.collasibearrow {
    font-size: 10px;
    margin-left: 3px;
}

p.accordionpara {
    margin: 0;
    font-size: 13px;
    color: #1d89e4;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}

.infocardfooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding: 8px 12px; */
}

.infocardfooterstart {
    background-color: white;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #f3f3f3;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

button.editbtndesign {
    text-transform: capitalize;
    font-size: 13px;
    background-color: #1d89e4;
    color: white;
    margin-right: 16px;
    opacity: 0.8;
    cursor: pointer;
    padding: 4px 30px
}

button.editbtndesign.disablebtn {
    pointer-events: none;
    opacity: 0.4;
}

button.editbtndesign.activebtn {
    cursor: pointer;
    opacity: 1;
}

button.mainbuttoncls.editbtndesign:hover {
    opacity: 1;
}

button.editbtndesign:hover, button.editbtndesign:focus {
    background-color: #1d89e4;
    color: white;
}

.ant-popover.ant-popover-placement-bottom {
    max-width: 9%;
    min-width: 9%;
}

.ant-popover.ant-popover-placement-bottom .ant-popover-inner-content {
    padding: 0;
}

p.deletetext {
    margin: 0;
    padding: 10px 12px 10px;
    cursor: pointer;
    font-size: 13px;
}

p.assigntext:hover, p.deletetext:hover {
    background-color: #1d89e408;
}

p.assigntext {
    margin: 0;
    padding-bottom: 2px;
    border-bottom: 1px solid #eaeaea;
    padding: 10px 12px 10px;
    font-size: 13px;
    cursor: pointer;
}

.customercardstart {
    background-color: white;
    /* border-radius: 4px; */
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    /* border: 1px solid #f3f3f3; */
    margin-top: 12px;
    /* position: relative; */
    /* padding: 12px; */
}

.leftpartsec {
    display: flex;
    width: 87%;
}
.conversationleft{
    border-right: 1px solid #f5f3f3;
    display: unset;
    width: 90%
}
.customerimagediv {
    height: 100px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    overflow: hidden;
    margin: 10px 0 10px 10px;
}

.attachfileinfo {
    display: flex;
    /* align-items: center;
    justify-content: center; */
    flex-flow: column
}
.uploadedfilenametext{
    width: 100%
}
img.customerimg {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

img.documentimgcls {
    height: 65px;
    width: 64px;
    object-fit: contain;
    object-position: center;
}

.customercardstart img.customerimg {
    object-fit: contain;
    background-color: #dbdbdb;
    }
.customercardstart .attachedfile img.customerimg{
    object-fit: contain;
}
p.customernametext {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    color: #142339;
    display: flex;
    align-items: center
}

p.namespan {
    width: 55%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

p.customersectitle {
    margin: 0;
    font-size: 12px;
    color: #638dee;
    font-weight: 500;
}

.leftpart {
    width: 100px;
}

p.customersecrow {
    margin: 5px 0 0;
    font-size: 12px;
    color: #142339;
    font-weight: 400;
}

.rightpart p.customersecrow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
}

span.customersecvalue.emailvalue {
    color: #4a88e9;
}

span.customersecvalue {
    color: #797979;
    line-height: 20px
}
.customersecvalue a{
    margin-left: 3px;
}
span.customerseclabel {
    font-weight: 500;
    line-height: 20px
}

/* .customersecondsec,.customerfirstsec,.customerthirdsec,.customerlastsec {
    padding: 10px;
} */

.customertypechip {
    /* background-color: transparent; */
    font-size: 11px;
    color: white;
    padding: 3px 5px;
    border-radius: 4px;
    margin-left: 3px;
    text-transform: uppercase
}

.aqtag {
    background-color: #53d266;
}

.completed {
    background-color: #53d266;
}

.cancelled {
    background-color: #f84b3f;
}

.notstarted {
    background-color: #ababab;
}

.inprogress {
    background-color: #fbb34d;
}

.apqtag {
    background-color: #fbb34d;
}

.astag {
    background-color: #f84b3f;
}

.asqtag {
    background-color: #129ff7;
}

.customerfirstsec {
    margin: 13px 0 10px 10px;
    padding-right: 24px;
    border-right: 1px solid #f5f3f3;
    width: 100%;
}

.customersecondsec {
    margin: 13px 0 10px 20px;
    padding-right: 20px;
    border-right: 1px solid #ebebeb;
    width: 250px;
}

.customerthirdsec {
    margin: 13px 0 10px 20px;
    width: 30%;
}

.customerlastsec {
    padding: 13px 10px 10px 20px;
    background-color: #f9fdff;
    border-left: 1px solid #e8f5fb;
    width: 14%
}

.alignemntdiv {
    display: flex;
    align-items: center
}

.forbetterview {
    display: flex;
}

.opendiv.extradetailssection {
    background-color: white;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 200px;
}

.closediv.extradetailssection {
    height: 0;
}

.opendiv.extradetailssection, .closediv.extradetailssection {
    -webkit-transition-property: height;
    -webkit-transition-duration: 1s;
    transition-property: height;
    transition-duration: 1s;
}

.closediv.extradetailssection .detailsdivstarted {
    height: 0;
    display: none;
}

/* .detailsdivstarted {
    padding: 12px;
    border-top: 1px solid #f3f3f3;
} */

p.detailstitle {
    margin: 0 0 5px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: #1d89e4;
    font-weight: 500;
}

.detailsinfo {
    font-size: 13px;
    color: #9a9a9a;
    font-family: 'Roboto', sans-serif;
}
.detailsinfo .forbetterview {
    width: 100% !important;
}

.customerdetailfooter {
    background-color: white
}

.customerdetailfooter .collasibearrow {
    display: none;
}

.infocardattachfile .customerimagediv {
    width: 30px;
    height: 30px;
    border-radius: 0;
    margin: 0 0 0 10px;
}

.attachedfile.infocardattachfile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    width: 25%;
}

.opacityzero.attachedfile.infocardattachfile {
    opacity: 0.3;
    pointer-events: none;
}

.opacityone.attachedfile.infocardattachfile {
    opacity: 1;
}

.accordiondiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 12px;
    border-top: 1px solid #ebebeb;
}

/* info card css end */

/* --Job Info card css start--- */

.longdesclimit {
    width: 90%
}

.longdesclimit span.customersecvalue {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
}

.alertmodaljobvisibility.alertmodalinner .ant-modal-body {
    text-align: center;
    display: flex;
    align-items: unset;
    justify-content: center;
    flex-direction: column;
    padding: 18px;
}

.alertmodaljobvisibility p.jobvisibilitytext {
    text-align: left;
    font-size: 16px;
    color: #464545;
}

.alertmodaljobvisibility .datetimepickermain {
    /* border: 1px solid #d4d4d4; */
    /* border-radius: 2px; */
    /* font-size: 15px; */
}

.alertmodaljobvisibility .datetimepickermain input[type="text"] {
    font-size: 14px;
    padding: 6px 10px;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
}
.alertmodaljobvisibility .datetimepickermain input[type="text"]:hover {
    border-color: #5291ff;
}

.alertmodaljobvisibility .alegrtfooter {
    padding-bottom: 0;
    padding-top: 15px;
}

.alertmodaljobvisibility button.buttonactive.mainbuttoncls.alertbuttoncls {
    background: #212121;
}

.jobstatuschangeddiv button.ant-switch.ant-switch-checked {
    background-color: #2ea200;
}

.jobstatuschangeddiv {
    width: 56%;
}

.jobstatuschangeddiv p {
    color: #1c2640;
    margin: 0;
    font-size: 13px;
    font-weight: 400;
}

.jobstatuschangeddiv button.ant-switch {
    margin-left: 12px;
}
.infocardfooter button.MuiButtonBase-root-27.MuiButton-root-1.MuiButton-text-3.MuiButton-flat-6.buttonactive.undefined.mainbuttoncls.callbtndesign {
    display:none;
}

.jobinfocard .customersecondsec {
    margin: 13px 0 10px 30px;
    padding-right: 0px;
}

.jobinfocard .customerimagediv {
    /* margin: 0 0 0 10px; */
}

/* .jobinfocard .customerthirdsec {
    width: unset;
} */

span.nystd.customertypechip {
    background-color: #dedede;
    color: #8f8f8f;
}

.jobinfocard .leftpartsec {
    width: 100%;
}

/* --Job info card css end----- */

/* ---Conversation card css start--- */

.rightside button.buttonactive.mainbuttoncls.addconvobutton {
    text-transform: capitalize;
    letter-spacing: 0;
    font-size: 12px;
    background-color: transparent;
    color: #1e89e4;
    padding: 4px 8px;
    border: 1px solid #1e89e4;
    opacity: 0.8;
}

.rightside button.buttonactive.mainbuttoncls.addconvobutton:hover {
    opacity: 1;
}

.leftside {
    /* padding: 10px 20px 10px; */
    /* border-right: 1px solid #f5f3f3; */
}

p.convodateandtime {
    font-size: 12px;
    color: #787878;
    margin: 0;
}

p.convoheading {
    margin: 0;
    color: #233046;
    font-size: 15px;
    font-weight: 500;
    padding: 3px 0 1px;
}

.convosubtext {
    margin: 0;
    font-size: 13px;
    color: #8a8a8a;
    line-height: 23px;
    padding-top: 4px;
}

.showCompanyName {
    margin: 0;
    font-size: 20px;
    color: #241772;
    align-items: center;
}

.conversationcard .customercardstart {
    padding: 12px;
    align-items: center;
}

/* ---Conversation card css end--- */

/* ---Work card css start--- */
.infocardfooter button.MuiButtonBase-root-72.MuiButton-root-46.MuiButton-text-48.MuiButton-flat-51.buttonactive.undefined.mainbuttoncls.callbtndesign{
    display: none;
}

.workcard .forbetterview {
    width: 40%;
}

.workcard .customerimagediv .sb-avatar__text {
    border-radius: 5px;
    opacity: 0.95;
}

.customercardparent .customerimagediv .sb-avatar__text {
    opacity: 0.95;
}

.workcard .forbetterview .customerfirstsec {
    width: 100%;
    border: none;
}
.customerimagediv.formanagerimgdiv {
    width: 100px;
}

.workcard .customersecondsec {
    border-left: 1px solid #ebebeb;
    margin-left: 0;
    padding-left: 49px;
    padding-right: 49px;
    margin-right: 40px;
    width: 30%;
}

/* .workcard .customerimagediv {
    width: 100px;
} */

.joblisttoparea {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.joblistbuttonarea {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cardparentdiv .ant-collapse-header {
    padding: 0 !important;
}

.cardparentdiv .ant-collapse-item.ant-collapse-no-arrow {
    border: none;
}

.cardparentdiv .ant-collapse {
    border: none;
    background-color: white;
    border-radius: 0 0 4px 4px;
}

.cardparentdiv .ant-collapse-content {
    border-top: 1px solid #ebebeb;
}

.forbetterview.workatatchment {
    width: 24%;
}

/* ---Work card css end---- */

/* ---Alert css start---- */

.alertmodalinner .ant-modal-body {
    text-align: center;
}
.alertmodalinner .alegrtfooter{
    padding-bottom: 0;
}
.alertmodalinner .ant-modal-body {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 24px;
}

.alerticondiv {
    background: #fad5d7;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #f04736;
}

p.alertheading {
    margin: 0;
    padding: 16px 0 5px;
    font-size: 18px;
    font-weight: 500;
    color: #374558;
}

p.alertheading p {
    margin: 0;
}

p.alersubtext {
    margin: 0;
    color: #b4b4b4;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 15px;
}

.alertmodalinner .ant-modal-footer {
    display: none;
}

.alegrtfooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 0px 20px;
    /* border-top: 1px solid #eee; */
}

button.buttonactive.mainbuttoncls.cancelbuttoncls {
    background: transparent;
    color: #888888;
    padding: 4px 20px;
    /* margin-right: 11px; */
    text-transform: capitalize;
    font-weight: 400;
    margin-right: 10px;
}

button.buttonactive.mainbuttoncls.cancelbuttoncls:hover {
    background-color: #eee;
}
button.buttonactive.mainbuttoncls.alertbuttoncls {
    background: #f04736;
    padding: 4px 20px;
    /* margin-right: 11px; */
    text-transform: capitalize;
    font-weight: 400;
    opacity: 0.8;
}

button.buttonactive.mainbuttoncls.alertbuttoncls:hover {
    opacity: 1;
}

.alertmodalinner .ant-modal {
    width: 420px !important;
}

.alertmodalinner .ant-modal-close {
    display: none;
}

.alertcloseicon {
    position: absolute;
    right: 5px;
    top: 4px;
    cursor: pointer;
    font-size: 14px;
    opacity: 0.4;
    padding: 5px 7px;
    line-height: 0;
    border-radius: 50%;
}
.alertcloseicon:hover {
    opacity: 0.6;
    background-color: #dadada;
}

/* ---Alert css end---- */

/* ----No data found start--- */

.nodatafound {
    background: #fff;
    height: 455px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    border: 1px solid #d6d6d6;
}

.nodatafounddiv {
    width: 200px;
    height: 200px;
}

img.nodatafoundimg {
    width: 100%;
    height: 100%;
}

p.nodatafoundtext {
    margin: 0;
    /* position: absolute; */
    font-size: 20px;
    color: #75a4fc;
    font-weight: 500;
}

/* ---NO data found end------ */

/* ClientCompanyCard css start */

.leftsidepart {
    display: flex;
    align-items: flex-start;
    width: 34%;
}

.leftsecond-column {
    margin: 13px 0 10px 10px;
    width: 100%;
}

.inner-columnsec {
    display: flex;
    /* width: 150px; */
    /* justify-content: space-between; */
}

.leftfirst-column .customerimagediv {
    width: 100px;
    height: 100px;
}

.leftsidepart, .rightsidepart {
    position: relative;
}

.leftsidepart::after {
    content: '';
    width: 1px;
    height: 85%;
    background: #eee;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
}

.rightsidepart::after {
    content: '';
    width: 1px;
    height: 85%;
    background: #eee;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}

.middlepart .customersecondsec {
    border: none;
    width: unset !important;
}

.middlepart .rightpart p.customersecrow {
    width: 270px;
}

.rightsidepart .customersecondsec {
    width: unset !important;
}

.client-companycard {
    position: relative;
}

.client-companycard .iconarea.ant-dropdown-trigger {
    font-size: 10px;
    color: #b1b1b1;
}

.client-companycard .createddropmenu {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.client-companycard .ant-collapse-item {
    background: #fff !important;
}

.client-companycard .ant-collapse-header {
    padding: 0 !important;
}

.client-companycard .ant-collapse {
    border: none;
}

.client-companycard .ant-collapse-content.ant-collapse-content-active {
    border: none;
    border-top: 1px solid #ebebeb;
}

/* ClientCompanyCard css end */

/* Pagination css start */

.globalpaginationstart {
    margin-top: 20px;
    text-align: right;
}
.forjoblistcls .globalpaginationstart{
    margin-bottom: 30px ;
    margin: 20px 37px 30px;
}
.globalpaginationstart .ant-pagination-item-active, .globalpaginationstart .ant-pagination-item-active a {
    background-color: #1d89e4;
    color: white
}

.globalpaginationstart .ant-pagination-item-active:focus, .globalpaginationstart .ant-pagination-item-active:hover {
    background-color: #1d89e4;
}

.globalpaginationstart .ant-pagination-item-active:focus a, .globalpaginationstart .ant-pagination-item-active:hover a {
    color: white;
}

/* Pagination css end */

/* Converstaion skeleton start */

.conversationskeletondivstart ul.ant-skeleton-paragraph {
    padding: 0;
    margin: 0;
}

.conversationskeletondivstart {
    background-color: white;
    border-radius: 4px;
    padding: 20px 20px;
    margin-bottom: 20px
}
.individualcardskeleton{
    padding: 0
}
.individualcardskeleton .ant-skeleton-header{
    padding-right: 0
}
.individualcardskeleton .ant-skeleton-header .ant-skeleton-avatar{
    width: 100%;
    padding: 4em;
    border-radius: 3px;
}
.individualcardskeleton .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar{
    background: linear-gradient(90deg, #cccccc 25%, #dadada 37%, #d4d4d4 63%) ;
    background-size: 400% 100%;

}
/* Conversation skeleton end */

button.buttonactive.undefined.mainbuttoncls.emailsubmitbutton {
    background-color: #004f94;
    text-transform: capitalize;
    font-size: 15px;
    padding: 4px 25px;
    font-weight: 400;
}

p.emailfieldtext {
    font-size: 14px;
    color: #a0a0a0;
    /* margin: 0; */
}

.emailfieldmodalcls .ant-modal-footer {
    display: none;
}

.emailfieldmodalcls .ant-form-item-label {
    line-height: 25px
}

.emailfieldmodalcls .ant-form-item-label label {
    font-size: 14px;
    color: #525252;
}

.emailfieldmodalcls .ant-modal-body {
    padding: 24px 30px 1px;
    font-size: 14px;
}
.emailfieldmodalcls button.ant-modal-close {
    display: none;
}
.emailfieldmodalcls .alertcloseicon {
    right: 11px;
    font-size: 16px;
    color: #4c4c4c;
    opacity: 0.5;
}
/* Responsive css start */

@media screen and (min-width:320px) and (max-width:768px) {
    .cardparentdiv.jobinfocard.customercardparent .customerfirstsec {
        width: 100%
    }
    .cardparentdiv.jobinfocard.customercardparent .jobstatuschangeddiv {
        width: 100%
    }
    .cardparentdiv.jobinfocard.customercardparent .infocardfooter {
        margin-top: 15px;
        justify-content: flex-start
    }
    .cardparentdiv.jobinfocard.workcard .hidden-xs {
        display: block !important
    }
    .cardparentdiv.jobinfocard.workcard .customersecondsec {
        padding-left: 0;
        border-left: none
    }
    .cardparentdiv.jobinfocard.workcard .createddropmenu {
        right: 7px;
        top: 7px;
    }
    .leftpartsec {
        display: unset;
        width: 100%
    }
    .infocardfooter button.MuiButtonBase-root-72.MuiButton-root-46.MuiButton-text-48.MuiButton-flat-51.buttonactive.undefined.mainbuttoncls.callbtndesign
        {        height: 100%;
        width: 100%;
        border-radius: 0;
        display: block;
        font-size: 14px;
        background: #f89e32;
        text-transform: capitalize;
    }
    .buttonComponent_start .customer-callbtn{
        background-color: #f89e32; 
        height: 45px;
        border-radius: 0;
        margin-top: 0;
    }
    .infocardfooter .buttonComponent_start.joblistfooter-callbtn{
        line-height: 43px;
    }
    button.mainbuttoncls.customer-callbtn{
        display: unset;
    }
    
    .buttonComponent_start.joblistfooter-call {
        line-height: 43px;
    }
    .customercardstart {
        display: unset
    }
    /* .customerimagediv {
        margin: 0;
    } */
    .customerfirstsec {
        margin: 8px 0 2px 10px;
        width: 68%;
        padding: 0;
        border-right: none
    }
    .rightpart p.customersecrow {
        width: 155px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .extradetailssection .forbetterview {
        border: none
    }
    .forbetterview {
        padding-bottom: 20px;
        border-bottom: 1px solid #ebebeb;
        align-items: center;
    }
    .customersecondsec {
        border-bottom: 1px solid #ebebeb;
        padding-right: 0;
        padding-bottom: 20px;
        border-right: none;
        margin-left: 0;
        width: 100%;
    }
    .cardparentdiv {
        margin-bottom: 15px;
        box-shadow: 0 1px 20px #00000017;
        padding: 12px 12px 12px;
        background-color: white;
        position: relative;
    }
    .customerthirdsec {
        margin-left: 0;
        border-bottom: none;
        width: 100%;
        padding-bottom: 0;
    }
    .customercardstart {
        border: none
    }
    .customerlastsec {
        border: none;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .extradetailssection {
        padding: 0;
    }
    .viewhistoryform.customerhistorypage .ant-collapse-content > .ant-collapse-content-box {
        padding-left: 0;
        padding-right: 0;
    }
    .customercard-mobileview .forbetterview {
        padding-bottom: 0px;
        border-bottom: none;
    }
    .customercardstart.customercard-mobileview {
        display: block;
        margin-top: 0;
        /* border-bottom: 1px solid #f1f0f0; */
        border-radius: 0;
        /* padding-bottom: 12px; */
        /* margin-bottom: 5px; */
    }
    .customercard-mobileview .createddropmenu {
        right: 0;
    }
    .customerimagediv.formanagerimgdiv{
        width:50px
        }
    .infocardfooterstart {
        border-width: 0;
    }
    .attachedfile.infocardattachfile {
        display: none
    }
    .accordiondiv {
        justify-content: flex-end;
    }
    /* Worker Info card responsive start */
    .workcard .forbetterview {
        width: 100%;
    }
    .cardparentdiv.jobinfocard.workcard .forbetterview {
        padding-bottom: 14px;
    }
    .cardparentdiv {
        position: relative;
    }
    .workcard .customercardstart {
        display: block;
        margin-top: 0;
    }
    .workcard .createddropmenu {
        right: -7px;
        top: -7px;
    }
    /* Worker Info card responsive end */
    /* .leftsidepart {
        width: 100%;
    } */
}

/* --1440 resolution css start--- */

@media screen and (min-width:1440px) {
    p.customersecrow {
        font-size: 14px;
    }
    p.customersectitle {
        font-size: 14px;
    }
    span.customersecrow.filesattached {
        font-size: 13px;
    }
    .leftpart {
        width: 110px;
    }
    .customersecondsec{
        width: 270px;
    }
    .leftpart {
        width: 115px;
    }
}

/*--1440 resolution css end--- */

@media screen and (min-width:768px) and (max-width:1023px) {
    .phonefieldinput input{
        border: 1px solid #ececec;
    border-radius: 2px;
    }
    .addjob i.anticon.anticon-plus {
        font-weight: 900;
        font-size: 9px;
    }
    /* .workcard .forbetterview .customerfirstsec{
        width: 67% !important;
    } */
    /* .addjob i.anticon.anticon-plus{
        font-size: 16px !important;
    } */
}
@media screen and (min-width:1024px) and (max-width:1366px){
    .addjob i.anticon.anticon-plus {
        font-weight: 900;
        font-size: 11px;
    }
}

/* Responsive css end */